import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

const NuevaBolsa = lazy(() => import ('./routes/NuevaBolsa'))
const NuevoPaquete = lazy(() => import ('./routes/NuevoPaquete'))
const Bolsas = lazy(() => import ('./routes/Bolsas'))
const NuevoCliente = lazy(() => import ('./routes/NuevoCliente'))
const Clientes = lazy(() => import ('./routes/Clientes'))
const NuevoEstablecimiento = lazy(() => import ('./routes/NuevoEstablecimiento'))
const Establecimiento = lazy(() => import('./routes/Establecimiento'));
const NuevoUsuario = lazy(() => import('./routes/NuevoUsuario'));
const Usuarios = lazy(() => import('./routes/Usuarios'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const Login = lazy(() => import('./routes/Login'));
const Paquetes = lazy (() => import('./routes/Paquetes'));
const NuevaConsiliacion = lazy (() => import('./routes//NuevaConsiliacion'));
// if(window.location.protocol=="https:")
// window.location.replace("http://unidosout.herokuapp.com");


const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/paquetes" component={Paquetes}/>
        <Route exact path="/usuarios" component={Usuarios}/>
        <Route exact path="/nuevousuario" component={NuevoUsuario}/>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/establecimiento" component={Establecimiento}/>
        <Route exact path="/nuevoestablecimiento" component={NuevoEstablecimiento}/>
        <Route exact path="/clientes" component={Clientes}/>
        <Route exact path="/nuevocliente" component={NuevoCliente}/>
        <Route exact path="/bolsas" component={Bolsas}/>
        <Route exact path="/nuevopaquete" component={NuevoPaquete}/>
        <Route exact path="/nuevabolsa" component={NuevaBolsa}/>
        <Route exact path="/nuevaConsiliacion" component={NuevaConsiliacion}/>
      </Switch>
    </Suspense>
  </Router>
);
export default App;
