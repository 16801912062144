import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

    if(cookies.get('myCat') === undefined && window.location.pathname !== '/')
    window.location.pathname = '/'
    else if(cookies.get('myCat') === "true" && window.location.pathname === '/')
    window.location.pathname = '/dashboard'

// alert(cookies.get('myCat'))
if ( window.location.pathname === '/')
ReactDOM.render(<App />, document.getElementById('sesion'));
else
ReactDOM.render(<App />, document.getElementById('root'));



serviceWorker.unregister();
